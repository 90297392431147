import {Link} from 'react-router-dom';
import design from './NavBar.module.css';
import './NavBar.module.css';
import menulogo from '../img/menu.png'
import langlogo from '../img/localization.png'
import closepic from '../img/close.png'
import ntslogo from '../img/ntslogonoborder.png'
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import i18n from '../i18n';



export const SidebarData = [

  {
    title: "Home",
    path: '/'
  },
  {
    title: "Contact",
    path: '/contact'
  },
  /*{
    title: "Pricing",
    path: '/pricing'
  },*/
  {
    title: "About",
    path: '/about'
  }
];

function NavBar () {

  const { t } = useTranslation();

  const translatedSidebar =  SidebarData.map(data => {
    
    return {
      title: t(data.title),
      path: data.path,
    }
  })



  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const [langmenu, setLangmenu] = useState(false);

  const showLanguageMenu = () => setLangmenu(!langmenu);

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const chooseLanguage = (e: any) => {
      e.preventDefault();
      i18n.changeLanguage(e.target.value);
      setSelectedLanguage(e.target.value);
      localStorage.setItem("lang", e.target.value);
      showLanguageMenu();
  }

  return (
    <>
      <nav className={design.primeheader} style={{opacity: '1', translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)'}}>
      <span style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)'}}><Link to=''> <img alt='Menu' className={design.navmenuimg} src={menulogo} onClick={showSidebar} height='25px' width='25px'/> </Link></span>
      <span className={design.ntslogo} style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)'}}>
        <span className={design.ntslogo} style={{opacity: '1'}}><Link to='/'><img alt='Menu' className={design.ntslogo} src={ntslogo}/></Link></span>
        <span className={design.ntslogo2} style={{opacity: '0', translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)'}}></span>
     </span>
        <span style={{opacity: '1', translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)'}}>
        <Link to=''><img alt='Language' className={design.langimg} src={langlogo} onClick={showLanguageMenu} height='25px' width='25px'/></Link>
          <select className={langmenu ? `${design.langdrop} ${design.active}` : `${design.langdrop}`} defaultValue={selectedLanguage} onChange={chooseLanguage}>  
              <option value="fi" >Suomi</option>
              <option value="en">English</option>
          </select>
        </span>
      </nav>
      <div className={design.breakpoint}></div>

      <nav className={sidebar ? `${design.navmenu} ${design.active}` : `${design.navmenu}`} >
        <ul className={design.navmenuitems} onClick={showSidebar}>
          <li className={design.navbartoggle}>
              <Link to='#'> <img alt='Menu' className={design.closemenuimg} src={closepic} height='25px' width='25px'/> </Link>
          </li>
          {translatedSidebar.map((item, index) => {
            return (
              <li key={index} className={design.navtext}>
                <Link to={item.path}>
                  <span>{item.title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
}

export default NavBar;