import design from './Home.module.css';
import NavBar from '../components/NavBar'
import {Link} from 'react-router-dom';
import arrowdown from '../img/down-arrow.png'
import benefitimg1 from '../img/study.png'
import benefitimg2 from '../img/calendar.png'
import benefitimg3 from '../img/server.png'
import benefitimg4 from '../img/custom.png'
import { useTranslation } from 'react-i18next'
import React, { useLayoutEffect, useRef } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

function Home () {

  const { t } = useTranslation();

  const content = useRef<HTMLDivElement>(null);
  
  const scrollDown = (ref: React.RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: ref.current?.offsetTop,
      behavior: 'smooth',
    });
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <>
    <div className={design.bgimg}></div>
    <div className={design.default}>
      <NavBar/>
          <div className={design.divcontainhome}>
              <div className={design.title}>{t("HomeTitle")}</div>
              <div className={design.subtitle}>{t("HomeSubTitle")}</div>

              <Link to='/contact'><div className={design.bigbutton}>
                {t("HomeCTAButton")}
              </div></Link>
          </div>
            <Link to=''> <img alt='ScrollDown' className={design.arrow} src={arrowdown} height='50px' width='50px' onClick={() => scrollDown(content)}/> </Link>
    </div>
    <div className={design.justcolor} ref={content}>
      <div className={design.pagefill}>
        <div className={design.divcontain}>
        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1} animateOnce>
          <div className={design.title2}>{t("ProblemSolutionTitleStart")}<div className={design.fonteffect}>{t("ProblemSolutionTitleColor")}</div>{t("ProblemSolutionTitleEnd")}</div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" delay={500} duration={2} animateOnce>  
          <div className={design.subtitle2}>{t("ProblemSolutionSubTitle")}</div>
        </AnimationOnScroll>
        </div>

        {/* <div className={design.divcontainweb}>
        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1} animateOnce>
          <div className={design.title2}>{t("ProblemSolution2TitleStart")}<div className={design.fonteffect}>{t("ProblemSolution2TitleColor")}</div>{t("ProblemSolution2TitleEnd")}</div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" delay={500} duration={2} animateOnce>  
          <div className={design.subtitle2}>{t("ProblemSolution2SubTitle")}</div>
        </AnimationOnScroll>
        </div> */}
        

        <div className={design.divcontainsmall}>
          <AnimationOnScroll animateIn="animate__fadeIn" duration={2} animateOnce>
            <div className={design.title2}>{t("QuadTitle")}</div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" duration={2} animateOnce>
          <div className={design.gridfeatures}>
            <div className={design.roundbox}>
              <p className={design.centerfeature}><img alt='Img' className={design.benefitimg} src={benefitimg1}/></p>
              <p className={design.centerfeatureTitle}>{t("HomeFeature1")}</p>
              <p className={design.centerfeature}>{t("HomeFeature2")}</p>
            </div>
            <div className={design.roundbox}>
              <p className={design.centerfeature}><img alt='Img' className={design.benefitimg} src={benefitimg2}/></p>
              <p className={design.centerfeatureTitle}>{t("HomeFeature3")}</p>
              <p className={design.centerfeature}>{t("HomeFeature4")}</p>
            </div>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" duration={2} animateOnce>
          <div className={design.gridfeatures}>
            <div className={design.roundbox}>
              <p className={design.centerfeature}><img alt='Img' className={design.benefitimg} src={benefitimg3}/></p>
              <p className={design.centerfeatureTitle}>{t("HomeFeature5")}</p>
              <p className={design.centerfeature}>{t("HomeFeature6")}</p>
            </div>
            <div className={design.roundbox}>
              <p className={design.centerfeature}><img alt='Img' className={design.benefitimg} src={benefitimg4}/></p>
              <p className={design.centerfeatureTitle}>{t("HomeFeature7")}</p>
              <p className={design.centerfeature}>{t("HomeFeature8")}</p>
            </div>
          </div>
          </AnimationOnScroll>
        </div>

        <div className={design.breakpoint}></div>

        <div className={design.divcontainsmall}>
          <AnimationOnScroll animateIn="animate__fadeIn" duration={2} animateOnce>
            <div className={design.title3}>{t("HomeBottomTitle")}</div>
            <Link to='/contact'><div className={design.bigbutton} onClick={scrollTop}>
              {t("HomeCTAButton")}
            </div></Link>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  </>
  )
}

export default Home