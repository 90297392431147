import design from './Footer.module.css';
import twitterlogo from '../img/twitter.png'
import linkedinlogo from '../img/linkedin.png'
import instagramlogo from '../img/instagram.png'
import facebooklogo from '../img/facebook.png'
import ntslogo from '../img/ntsmountaincolor.png'
import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import i18n from '../i18n';

function Footer () {

    const { t } = useTranslation();

    const [langmenu, setLangmenu] = useState(false);

    const showLanguageMenu = () => setLangmenu(!langmenu);

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const chooseLanguage = (e: any) => {
        e.preventDefault();
        i18n.changeLanguage(e.target.value);
        setSelectedLanguage(e.target.value);
        localStorage.setItem("lang", e.target.value);
        showLanguageMenu();
    }

    const scrollTop = () => {
        window.scrollTo({
          top: 0,
        });
      };

    return (
        <footer className={design.footer}>
            <Link to='/'><div className={design.logo}><img src={ntslogo} height='30px' onClick={scrollTop}/></div></Link>
            <div className={design.breakpoint}></div>
                <div className={design.links}>
                    <Link to='/'><div className={design.link} onClick={scrollTop}>{t("Home")}</div></Link>
                    <Link to='/contact'><div className={design.link} onClick={scrollTop}>{t("Contact")}</div></Link>
                    {/* <Link to='/pricing'><div className={design.link} onClick={scrollTop}>{t("Pricing")}</div></Link> */}
                    <Link to='/about'><div className={design.link} onClick={scrollTop}>{t("About")}</div></Link>
                    <a className={design.pointer}>
                    <select className={langmenu ? `${design.langdrop} ${design.active}` : `${design.langdrop}`} defaultValue={selectedLanguage} onChange={chooseLanguage}>  
                        <option value="fi" >Suomi</option>
                        <option value="en">English</option>
                    </select>
                    <div className={design.link} onClick={showLanguageMenu}>{t("Language")}</div></a>
                    
                </div>
                <div className={design.breakpoint}></div>
                <div className={design.smedialogos}>
                    <a href='https://www.linkedin.com/company/100048553'><img src={linkedinlogo} height='30px' width='30px'/></a>
                    <a href='https://www.instagram.com/nordictrendsolutions/'><img src={instagramlogo} height='30px' width='30px'/></a>
                    <a href='https://www.facebook.com/profile.php?id=61551868583712'><img src={facebooklogo} height='30px' width='30px'/></a>
                </div>
                <Link to='https://nordictrendsolutions.com/'><div className={design.product}>{t("productinfo")}</div></Link>
        </footer>
    );
}

export default Footer;