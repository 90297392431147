import './App.css';
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Contact from './pages/Contact'
import About from './pages/About'
import NavBar from './components/NavBar'
import NavBarSticky from './components/NavBarSticky'
import Footer from './components/Footer'
import { useState } from 'react';
import LanguageSelector from './components/LangSelect';
import Pricing from './pages/Pricing';
import './fonts/font.css'

function App() {

  /*const [pos, setPos] = useState(true);
  
  function navPos() {
      if (window.scrollY >= 35) {
          setPos(false)
      } else {
          setPos(true)
      }
  }*/
  
  //window.addEventListener("scroll", navPos)
// {pos ? <NavBar/> : <NavBarSticky/>}


  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/about' element={<About />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
