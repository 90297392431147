import design from './Pricing.module.css';
import NavBar from '../components/NavBar'
import arrowdown from '../img/down-arrow.png'
import { useTranslation } from 'react-i18next'
import {Link} from 'react-router-dom';
import React, { useRef } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

function Pricing () {

  const { t } = useTranslation();

  const content = useRef(null);
  
  const scrollDown = (ref: any) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <div className={design.default}>
        <NavBar/>
          <div className={design.pagefill}>
              <div className={design.title}>{t("Pricing")}</div>
          </div>
          <Link to=''> <img alt='ScrollDown' className={design.arrow} src={arrowdown} height='50px' width='50px' onClick={() => scrollDown(content)}/> </Link>
      </div>
      <div className={design.justcolor} ref={content}>
        <div className={design.pagefill}>
          <div className={design.divcontainsmall}>
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={1.5} animateOnce>
              <div className={design.title2}>{t("PricingTitle")}<div className={design.fonteffect}>{t("PricingTitleColor")}</div>{t("PricingTitleEnd")}</div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" delay={500} duration={2} animateOnce>
            <div className={design.subtitle}>{t("PricingSubTitle")}</div>
            <div className={design.subtitle}>{t("PricingSubTitle2")}</div>
            <div className={design.subtitle}>{t("PricingSubTitle3")}</div>
          </AnimationOnScroll>
          </div>
            
          <div className={design.breakpoint}></div> 
          <div className={design.divcontain}>
          
          </div>
        </div>
      </div>
    </>
  )
}

export default Pricing
