import design from './About.module.css';
import NavBar from '../components/NavBar'
import {Link} from 'react-router-dom';
import arrowdown from '../img/down-arrow.png'
import { useTranslation } from 'react-i18next'
import React, { useRef } from 'react';

function About () {

  const { t } = useTranslation();

  const content = useRef(null);

  const mail = t("ContactInfo.email");
  
  const scrollDown = (ref: any) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className={design.default}>
        <NavBar/>
          <div className={design.pagefill}>
              <div className={design.title}>{t("About")}</div>
          </div>
          <Link to=''> <img alt='ScrollDown' className={design.arrow} src={arrowdown} height='50px' width='50px' onClick={() => scrollDown(content)}/> </Link>
      </div>
      <div className={design.justcolor} ref={content}>
        <div className={design.pagefill}>
          <div className={design.divcontain}>
            <div className={design.title2}>{t("AboutUsTitle")}</div>
            <div className={design.subtitle2}>{t("AboutUsPageFill")}</div>
          </div>
            
            <div className={design.breakpoint}></div>
            <div className={design.divcontainsmall}>
          <div className={design.contactinfo}>
            <li>{t("ContactInfo.companyname")}</li>
            <li>{t("ContactInfo.subtext")}</li>
            <li>{t("ContactInfo.businessid")}</li>
            <li>{t("ContactInfo.phone")}</li>
            <li><Link to={'mailto:' +  mail}>{t("ContactInfo.email")}</Link></li>
          </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
