import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsInEng from '../localisation/en/lang.json';
import translationsInFinnish from '../localisation/fi/lang.json';

const resources = {
  en: {
    translation: translationsInEng
  },
  fi: {
    translation: translationsInFinnish
  },
};

const selectedLang = String(localStorage.getItem("lang"));

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: selectedLang,
    debug: true,
    fallbackLng: "fi",
    interpolation: {
      escapeValue: false
    },
    ns: "translation",
    defaultNS: "translation"
  });

export default i18n;